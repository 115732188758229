import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import { REDTRK_URL } from '../constants';
import MoreInfo from './MoreInfo/MoreInfo';
import './Samples.css';
import SamplesBody from './SamplesBody/SamplesBody';
import mandm from '../assets/PromoImages/mms.jpeg';
import chickfila from '../assets/PromoImages/chickfila.jpeg';
import ihop from '../assets/PromoImages/ihop.jpeg';
import lays from '../assets/PromoImages/lays_samples.jpeg';
import quiznos from '../assets/PromoImages/quiznos.png';
import mcd from '../assets/PromoImages/mcd.png';
import Footer from '../components/Footer/Footer';
import fastFootIMG from '../assets/fasfood.png';
import fastFootDarkIMG from '../assets/fastfood3.png';
import candiesIMG from '../assets/candies.jpeg';
import candiesDarkIMG from '../assets/m&ms-backgr3-dark.png'
import chipsIMG from '../assets/chips.jpg';
import chipsDarkIMG from '../assets/chips_dark.png';
import mcdIMG1 from '../assets/mcsbckg1.png';
import mcdIMGdark1 from '../assets/mcsbckg1-dark.png';
import kfcIMG from '../assets/kfc.png';
import wendysIMG from '../assets/wendys.png';
import burgerkingIMG from '../assets/burgerking.png';
import kfcBckrg1 from '../assets/kfcbackgr1.png';
import burgerkingBckrg1 from '../assets/burgerkingbackgr1.png';
import wendysBckrg1 from '../assets/wendysbackgr1.png';
import pizzaHutbackgr1 from '../assets/pizahut.jpg';
import fortniteBackgr1 from '../assets/fortniteBackgr1.png'
import fortniteBackgr2 from '../assets/fortniteBackgr2.png'
import fortniteBackgr3 from '../assets/fortniteBackgr3.png'
import fortniteBackgr4 from '../assets/fortniteBackgr4.png'
import robloxBackgr1 from '../assets/RobloxBackgr1.png'
import robloxBackgrdark from '../assets/robloxBackgr2-dark.png'
import ps5fnpic from '../assets/PromoImages/ps5fn.jpeg'
import ps5fnpic2 from '../assets/PromoImages/ps5fn2.jpeg.png'
import ps5pic from '../assets/PromoImages/ps5_1000_toward.png'
import kitkatPic from '../assets/PromoImages/kitkatSamplesPic.png'
import kitkatBackgr1 from '../assets/kitkatBackgr1.webp'
import subwayPic from '../assets/PromoImages/Subway Collage2.png'
import subwayBackgr from '../assets/subwayBackgr2.jpg'
import cocaColaFridgePic from '../assets/cocalCola Fridge.png'
import cocaColaBackgr1 from '../assets/cocaColaBackgr1.png'
import tacobellpic from '../assets/PromoImages/tacobellpix21.png'
import tacaoBellBackgr from '../assets/tacobellBackgr1.png'
import dunkinpic from '../assets/PromoImages/dunkinpix1.png'
import dunkinBackgr1 from '../assets/dunkindonutsBackgr3.png'
import robloxsamsungs22 from '../assets/PromoImages/RobloxS22Blue.png'
import pizzaHutpic from '../assets/PromoImages/Pizza Hut Samplespng2.png'
import reesespic from '../assets/PromoImages/reseespic1.png'
import reesesBackgr1 from '../assets/reesesBackgr1.avif'
import starbuckPic from '../assets/PromoImages/starbuckspic.png'
import starbuksBackgr1 from '../assets/startbucksBackgr1.png'
import bathPic1 from '../assets/PromoImages/bathbodypic.png'
import bathBackg1 from '../assets/bathbackgr1.png'
import vicpic from '../assets/PromoImages/victoriasecretpic.png'
import vicBackgr1 from '../assets/vicBacgr1.webp'
import macpic from '../assets/PromoImages/maccosmeticspic.png'
import macBackgr from '../assets/macbackgr1.png'
import HeaderSamples from '../components/HeaderSamples/HeaderSamples';


const Samples = ({ pageName }) => {
  const [title, setTitle] = useState('Gift Card Giveaway! Register to Continue!');
  const [headline, setHeadline] = useState('');
  const [picURL, setpicURL] = useState('');
  const [backGr1, setBackgr1] = useState('none');
  const [backGr2, setBackgr2] = useState('none');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pageName.includes('m&ms') && pageName.includes('samples')) {
      setTitle(`M&M's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(mandm);
      setBackgr1(candiesIMG);
      setBackgr2(candiesDarkIMG);
    } else if (pageName.includes('chickfila') && pageName.includes('samples')) {
      setTitle(`Chick-Fil-A Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(chickfila);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('lays') && pageName.includes('samples')) {
      setTitle(` Lays Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(lays);
      setBackgr1(chipsIMG);
      setBackgr2(chipsDarkIMG);
    } else if (pageName.includes('ihop') && pageName.includes('samples')) {
      setTitle(`iHop Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(ihop);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('quiznos') && pageName.includes('samples')) {
      setTitle(`Quiznos Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(quiznos);
      setBackgr1(fastFootIMG);
      setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('mcdonalds') && pageName.includes('samples')) {
      setTitle(`McDonald's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(mcd);
      setBackgr1(mcdIMG1);
      // setBackgr2(mcdIMGdark1);
    }else if (pageName.includes('kfc') && pageName.includes('samples')) {
      setTitle(`KFC Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(kfcIMG);
      setBackgr1(kfcBckrg1);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('wendys') && pageName.includes('samples')) {
      setTitle(`Wendy's Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(wendysIMG);
      setBackgr1(wendysBckrg1);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('burgerking') && pageName.includes('samples')) {
      setTitle(`Burger King Samples!`);
      setHeadline(`You Qualify to Get`);
      setpicURL(burgerkingIMG);
      setBackgr1(burgerkingBckrg1);
      // setBackgr2(fastFootDarkIMG);
    } else if (pageName.includes('fnps5') && pageName.includes('samples')) {
      setTitle(`PlayStation 5`);
      setHeadline(`You Qualify to Get`);
      setpicURL(ps5fnpic);
      setBackgr1(fortniteBackgr1);
      // setBackgr2(fortniteBackgr3);
    } else if (pageName.includes('fn2ps5') && pageName.includes('samples')) {
      setTitle(`PlayStation 5`);
      setHeadline(`You Qualify to Get`);
      setpicURL(ps5fnpic2);
      setBackgr1(fortniteBackgr1);
      // setBackgr2(fortniteBackgr2);
    }  else if (pageName.includes('roblox') && pageName.includes('samsungs22')) {
      setTitle(`Samsung S22`);
      setHeadline(`You Qualify to Get`);
      setpicURL(robloxsamsungs22);
      setBackgr1(robloxBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('pizzahut') && pageName.includes('samples')) {
      setTitle(`Pizza Hut Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(pizzaHutpic);
      setBackgr1(pizzaHutbackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('kitkat') && pageName.includes('samples')) {
      setTitle(`Kit Kat Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(kitkatPic);
      setBackgr1(kitkatBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('subway') && pageName.includes('samples')) {
      setTitle(`Subway Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(subwayPic);
      setBackgr1(subwayBackgr);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('cocacolafridge') && pageName.includes('samples')) {
      setTitle(`Coca Cola Fridge`);
      setHeadline(`You Qualify to Get`);
      setpicURL(cocaColaFridgePic);
      setBackgr1(cocaColaBackgr1);
      // setBackgr2(robloxBackgrdark);
    }  else if (pageName.includes('tacobell') && pageName.includes('samples')) {
      setTitle(`Taco Bell Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(tacobellpic);
      setBackgr1(tacaoBellBackgr);
      // setBackgr2(robloxBackgrdark);
    }  else if (pageName.includes('dunkindonuts') && pageName.includes('samples')) {
      setTitle(`Dunkin' Donuts Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(dunkinpic);
      setBackgr1(dunkinBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('reeses') && pageName.includes('samples')) {
      setTitle(`Reese's Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(reesespic);
      setBackgr1(reesesBackgr1);
      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('starbucks') && pageName.includes('samples')) {
      setTitle(`Starbucks Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(starbuckPic);
      setBackgr1(starbuksBackgr1);
      // setBackgr2(robloxBackgrdark);
    } 
    else if (pageName.includes('bathbodyworks') && pageName.includes('samples')) {
      setTitle(`Bath & Body Works Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(bathPic1);
      setBackgr1(bathBackg1);
      // setBackgr2(robloxBackgrdark);
    } 
    else if (pageName.includes('victoriassecret') && pageName.includes('samples')) {
      setTitle(`Victoria's Secret Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(vicpic);
      setBackgr1(vicBackgr1);
      // setBackgr2(robloxBackgrdark);
    } 
    else if (pageName.includes('maccosmetics') && pageName.includes('samples')) {
      setTitle(`MAC Cosmetics Samples`);
      setHeadline(`You Qualify to Get`);
      setpicURL(macpic);
      setBackgr1(macBackgr);
      // setBackgr2(robloxBackgrdark);
    } 
  }, []);

  // useEffect(() => {
  //   const existingUser = window.localStorage.getItem('S&R');

  //   if (existingUser) {
  //     setLoading(true);
  //     const doLoading = setTimeout(() => {
  //       window.location.replace(
  //         `${REDTRK_URL}?email=${existingUser}&sub2=${existingUser}&sub3=repeating_user`
  //       );
  //     }, 1200);
  //     return () => clearTimeout(doLoading);
  //   }
  // }, []);

  //
  // more infor to be this Khaki color: rgb(240,230,140)

  return (
    <div>
      <HeaderSamples  pageType="samples" title={title} headline={headline} />
      {/* <Header pageType="samples" backcolor="rgb(255,215,0)" /> */}
      <div className="main-container">
        {loading ? (
          <div className="loader-div">
            <h2 id="loading-text">Checking if you qualify...</h2>
            <br />
            <br />
            <Loader width="15.5em" height="12.23em" style={{ border: '1px solid black' }} />
          </div>
        ) : (
          <SamplesBody
            title={title}
            headline={headline}
            picURL={picURL}
            backgroundPic1={backGr1}
            backgroundPic2={backGr2}
          />
        )}

        <MoreInfo backcolor="rgb(240,230,140)" />
      </div>
      <Footer pageType="samples" />
    </div>
  );
};

export default Samples;
